<template>
  <v-dialog v-model="addGradeDialog" persistent width="500">
    <v-card style="">
      <v-card-text>
        <h3 class="modal-header">{{ $t("Add Grades") }}</h3>
        <v-col cols="12" sm="12">
          <label>{{ $t("Grades") }}</label>
          <v-autocomplete
            :items="allGrades"
            v-model="selectedGradesItems"
            item-text="grade_name"
            item-value="id"
            chips
            solo
            multiple
          ></v-autocomplete>
        </v-col>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="modal-btn-cancel" @click="closeDialog(false)">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          class="modal-btn-save"
          :loading="loading"
          @click="saveGradesToFeePlan"
        >
          {{ $t("Save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "AddNewGrades",
  props: ["addGradeDialog", "selectedGrades", "planId"],
  mixins: [validationMixin],
  data() {
    return {
      allGrades: [],
      selectedGradesItems: [],
      loading: false
    };
  },
  watch: {
    addGradeDialog: {
      handler() {
        if (this.addGradeDialog == true) {
          this.getAllGrades();
          this.selectedGrades.forEach(grade => {
            this.selectedGradesItems.push(grade.id);
          });
        } else {
          this.selectedGradesItems = [];
        }
      }
    }
  },

  methods: {
    saveGradesToFeePlan() {
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/paymentplans/saveGradesToFeePlan/" + this.planId,
          { gradeIds: this.selectedGradesItems },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            this.closeDialog(true);
            this.loading = false;
          }
        });
    },
    /* get all grades KG1, KG2 */
    getAllGrades() {
      axios
        .get(this.getApiUrl + "/paymentplans/getAllGrades", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.allGrades = response.data.data;
        });
    },

    closeDialog(save) {
      if (save == false) {
        this.$emit("childToParent", false, false);
      } else {
        this.$emit("childToParent", false, true);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
